@import "../scss/bootstrap.scss";
@import "../scss/icons.scss";
@import "../scss/app.scss";

//SignUp Modal close
.close {
  background: transparent;
  border: 0;
  font-size: 12px;
  padding: 1.35rem 1.25rem;
  background: transparent
    escape-svg(
      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#000'><path d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/></svg>")
    )
    center / 1em auto no-repeat;
  position: absolute;
  top: 8px;
  right: 8px;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;
}

.close span {
  display: none;
}

//section country/type layout 1
.filter-search-form {
  .selectForm__inner {
    box-shadow: none !important;
    border: 0;
    font-size: 15px;
    height: 60px;
    padding: 16px 6px 15px 45px !important;
    border-radius: 0;
  }
}

.filter-input-box {
  padding: 16px 6px 15px 45px;
}

.filter-search-form {
  .selectForm__inner {
    box-shadow: none !important;
    border: 0;
    font-size: 15px;
    height: 60px;
    padding: 16px 6px 15px 45px !important;
    border-radius: 0;
  }
}

//JobList
.filler-job-form {
  .form-select-option {
    box-shadow: none !important;
    border: 1;
    font-size: 14px;
    height: 44px;
    padding: 6px 6px 7.5px 45px !important;
    border-radius: 6px;
  }
}

.filter-job-input-box-option {
  box-shadow: none !important;
  border: 1;
  font-size: 14px;
  height: 44px;
  padding: 6px 6px 7.5px 45px !important;
  border-radius: 6px;
}

//JobGrid
.filler-job-form {
  .selectForm__inner {
    box-shadow: none !important;
    border: 1;
    font-size: 14px;
    height: 44px;
    padding: 6px 6px 7.5px 45px !important;
    border-radius: 6px;
  }
}

.filler-job-input-box {
  box-shadow: none !important;
  border: 1;
  font-size: 14px;
  height: 44px;
  padding: 6px 6px 7.5px 45px !important;
  border-radius: 6px;
}

//Feature icon,Pricing Icon
.featrue-icon,
.pricing-icon,
.popu-category-icon,
.avatar-xs,
.mode-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

[class^="uil-"]:before,
[class*=" uil-"]:before {
  line-height: inherit;
}
//Candidate List (BookMark Icon)
.favorite-icon {
  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

//Profile status Icon
.profile-active {
  padding: 5px;
}

//Candidate Details icons
.list-unstyled {
  li {
    .icon {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }
}

//About us/pricing page Counter
.counter_custom {
  .counter {
    height: 1.2em !important;
  }
}

.top-bar {
  display: flex;
  align-items: center;
  height: 44px;
}

.noUi-horizontal {
  height: 10px;
  .noUi-handle {
    height: 18px;
    width: 18px;
    top: -5px;
  }
}

.noUi-tooltip {
  font-size: 12px;
  padding: 1px 4px;
}

.slidecontainer {
  width: 100%;
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #766df4;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #766df4;
  cursor: pointer;
}
//react select
.css-1ie0jvc-control {
  background-color: var(--#{$prefix}secondary-bg) !important;
  border-color: var(--#{$prefix}border-color) !important;

  .css-1dimb5e-singleValue {
    // color: var(--#{$prefix}body-color)!important;
  }
}

.css-bnv882-control {
  background-color: var(--#{$prefix}secondary-bg) !important;
}
.css-1u9des2-indicatorSeparator {
  background-color: var(--#{$prefix}tertiary-bg) !important;
}
.css-b62m3t-container {
  .css-13cymwt-control {
    background-color: var(--#{$prefix}secondary-bg);
    color: var(--#{$prefix}body-color);
    border-color: var(--#{$prefix}border-color);
    &:hover,
    &:focus {
      border-color: var(--#{$prefix}primary);
    }

    .css-1p3m7a8-multiValue {
      color: var(--#{$prefix}white);
      background-color: var(--#{$prefix}primary);
      .css-wsp0cs-MultiValueGeneric {
        color: var(--#{$prefix}white);
      }

      .css-1u9des2-indicatorSeparator {
        border-color: var(--#{$prefix}border-color);
      }
    }
  }

  .css-1fdsijx-ValueContainer {
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }

  .css-t3ipsp-control {
    background-color: var(--#{$prefix}secondary-bg);
    color: var(--#{$prefix}body-color);
    border-color: var(--#{$prefix}border-color);

    .css-1p3m7a8-multiValue {
      color: var(--#{$prefix}white);
      background-color: var(--#{$prefix}primary);
      .css-wsp0cs-MultiValueGeneric {
        color: var(--#{$prefix}white);
      }

      .css-1u9des2-indicatorSeparator {
        border-color: var(--#{$prefix}border-color);
      }
    }
  }

  .css-1nmdiq5-menu {
    background-color: var(--#{$prefix}secondary-bg);
    color: var(--#{$prefix}body-color);
    border-color: var(--#{$prefix}border-color);
    .css-d7l1ni-option {
      color: $white;
      background-color: var(--#{$prefix}primary);
      &:hover,
      &:active,
      &:focus {
        background-color: var(--#{$prefix}primary);
      }
    }
  }
}

@media (max-width: 604.00px) {
  .registration-form .g-0 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .selectForm__inner{
    width: 85%;
    margin-left: 7%;
  }

  #btn-gt-search > button{
    width: 85% !important;
    margin-left: 7%;
  }

  .uil-map-marker, .uil-briefcase-alt {
    margin-left: 6%;
  }
}

@media (max-width: 440.00px) {
  .registration-form .g-0 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .selectForm__inner {
    width: 83%;
    margin-left: 8%;
  }

  #btn-gt-search > button{
    width: 83% !important; 
    margin-left: 8%;
  }

  .uil-map-marker, .uil-briefcase-alt {
    margin-left: 8%;
  }
}

@media (max-width: 420.00px) {
  .registration-form .g-0 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .selectForm__inner {
    width: 80%;
    margin-left: 10%;
  }

  #btn-gt-search > button{
    width: 80% !important; 
    margin-left: 10%;
  }

  .uil-map-marker, .uil-briefcase-alt {
    margin-left: 10%;
  }
}

@media (max-width: 361.00px) {
  .registration-form .g-0 {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .selectForm__inner {
    width: 75%;
    margin-left: 12%;
  }

  #btn-gt-search > button{
    width: 75% !important; 
    margin-left: 12%;
  }

  .uil-map-marker, .uil-briefcase-alt {
    margin-left: 12%;
  }
}


