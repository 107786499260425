//Fonts
@import "custom/fonts/fonts";

//Core files
@import "./node_modules/bootstrap/scss/functions";
@import "./node_modules/bootstrap/scss/variables";
@import "variables";
@import "variables-dark";
@import "./node_modules/bootstrap/scss/mixins.scss";

// Structure
@import "custom/structure/topbar";
@import "custom/structure/page-title";
@import "custom/structure/footer";

// Components
@import "custom/components/helper";
@import "custom/components/components";
@import "custom/components/form";
@import "custom/components/preloader";

// Plugins
@import "custom/plugins/select";
@import "custom/plugins/glight-box";

// Pages
@import "custom/pages/home";
@import "custom/pages/categories";
@import "custom/pages/job-list";
@import "custom/pages/cta";
@import "custom/pages/process";
@import "custom/pages/testimonial";
@import "custom/pages/blog";
@import "custom/pages/about";
@import "custom/pages/service";
@import "custom/pages/team";
@import "custom/pages/pricing";
@import "custom/pages/faq";
@import "custom/pages/job-grid";
@import "custom/pages/job-details";
@import "custom/pages/candidate-list";
@import "custom/pages/candidate-datails";
@import "custom/pages/authentication";
@import "custom/pages/coming-soon";
@import "custom/pages/profile";
@import "custom/pages/company-profile";
@import "custom/pages/switcher";

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.gt-trainer-name {
  font-family: "Poppins", sans-serif;
  color: white;
  background-color: #232e4b;
  width: 300px;
  text-align: center;
  font-size: 22px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.gt-trainer-name-modal {
  font-family: "Poppins", sans-serif;
  color: white;
  background-color: #232e4b;
  width: 250px;
  text-align: center;
  font-size: 16px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
}
